import React from 'react';

const EmailCardOnly = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="80"
    height="80"
    viewBox="0 0 80 80"
  >
    <defs>
      <filter
        id="a"
        width="118.1%"
        height="118.1%"
        x="-7.6%"
        y="-7.6%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="1" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <path id="b" d="M0 0h71.972v71.972H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" filter="url(#a)" transform="translate(3 3)">
      <mask id="c" fill="#fff">
        <use xlinkHref="#b" />
      </mask>
      <path
        fill="#D7410F"
        d="M68.304 40.719a.823.823 0 0 1 .124-.94l3.37-3.37a.599.599 0 0 0 0-.845l-3.37-3.37a.823.823 0 0 1-.124-.94l2.383-4.128a.6.6 0 0 0-.219-.817l-4.128-2.383a.825.825 0 0 1-.362-.876l1.233-4.604a.599.599 0 0 0-.422-.731l-4.604-1.234a.824.824 0 0 1-.577-.752v-4.767a.6.6 0 0 0-.598-.597h-4.766a.824.824 0 0 1-.753-.578l-1.233-4.603a.6.6 0 0 0-.732-.423l-4.604 1.234a.824.824 0 0 1-.876-.363l-2.383-4.128a.6.6 0 0 0-.816-.219L40.719 3.67a.824.824 0 0 1-.94-.124l-3.37-3.37a.599.599 0 0 0-.845 0l-3.37 3.37a.824.824 0 0 1-.94.124l-4.128-2.384a.6.6 0 0 0-.817.22l-2.383 4.127a.825.825 0 0 1-.876.363L18.446 4.76a.599.599 0 0 0-.732.423l-1.233 4.603a.824.824 0 0 1-.752.578h-4.767a.6.6 0 0 0-.597.597v4.767c0 .328-.26.667-.578.752l-4.604 1.234a.599.599 0 0 0-.422.731l1.234 4.604a.825.825 0 0 1-.363.876L1.504 26.31a.6.6 0 0 0-.219.817l2.384 4.127a.824.824 0 0 1-.124.94l-3.37 3.37a.599.599 0 0 0 0 .846l3.37 3.37a.824.824 0 0 1 .124.94l-2.384 4.128a.6.6 0 0 0 .219.816l4.128 2.383a.824.824 0 0 1 .363.876L4.76 53.526a.6.6 0 0 0 .422.732l4.604 1.234a.824.824 0 0 1 .578.752v4.766a.6.6 0 0 0 .597.598h4.767c.328 0 .666.26.752.577l1.233 4.604a.599.599 0 0 0 .732.422l4.604-1.233a.825.825 0 0 1 .876.363l2.383 4.127a.6.6 0 0 0 .817.22l4.127-2.384a.823.823 0 0 1 .94.124l3.37 3.37a.599.599 0 0 0 .845 0l3.371-3.37a.823.823 0 0 1 .94-.124l4.128 2.383a.6.6 0 0 0 .816-.219l2.383-4.127a.824.824 0 0 1 .876-.363l4.604 1.233a.6.6 0 0 0 .732-.422l1.233-4.604a.824.824 0 0 1 .753-.577h4.766a.599.599 0 0 0 .598-.598v-4.766c0-.329.26-.667.577-.752l4.604-1.234a.6.6 0 0 0 .422-.732l-1.233-4.604a.824.824 0 0 1 .362-.876l4.128-2.383a.6.6 0 0 0 .22-.816l-2.384-4.128z"
        mask="url(#c)"
      />
      <text fill="#FFF" fontFamily="WorkSans-Bold, Work Sans" fontSize="14" fontWeight="bold">
        <tspan x="12.364" y="34">
          E-Card
        </tspan>{' '}
        <tspan x="20.855" y="50">
          Only
        </tspan>
      </text>
    </g>
  </svg>
);

export default EmailCardOnly;
