import React from 'react';
import { useDispatch } from 'react-redux';
import { AddProduct } from '../../store/Slices/gifts/basketSlice';
import PropTypes from 'prop-types';
import Button from '../Button';
import PlusIcon from '../../public/static/icons/plus-gifts.svg';
import { isSantaLetterProduct } from '../../utils/Gifts/products';

import s from './index.module.scss';

const AddToBasketButtonForTeasers = ({ product }) => {
  const dispatch = useDispatch();

  const addToBasketClicked = () => {
    dispatch(AddProduct(product));
    //
    // if (location.pathname.match(/^\/basket|\/cards|\/checkout$/)) {
    //   window.scrollTo(0, 0);
    //   dispatch(basketBannerActions.hide());
    //   dispatch(
    //     messageActions.show(`${product.title} added, thanks for showing your support.`, {
    //       className: 'success-green',
    //       timeout: 4000,
    //     })
    //   );
    // }
  };

  if (isSantaLetterProduct(product)) {
    return null;
  }

  return (
    <Button
      className={s['add-to-basket-for-teasers']}
      onClick={() => addToBasketClicked()}
      data-component="add-to-basket-for-teasers"
      type="secondary"
      isOutlined
      isMobileBlock
      size="small"
    >
      <PlusIcon className={s['add-to-basket-for-teasers__plus-icon']} />
      Add to basket
    </Button>
  );
};

AddToBasketButtonForTeasers.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    categoryIds: PropTypes.arrayOf(PropTypes.number),
    image: PropTypes.object,
    url: PropTypes.object,
    shortDescription: PropTypes.string,
    actionImage: PropTypes.object,
    actionDescription: PropTypes.string,
    annotation: PropTypes.string,
    giftsInBundle: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

export default AddToBasketButtonForTeasers;
