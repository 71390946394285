import PropTypes from 'prop-types';
import { formattedPrice } from '../../utils/Gifts/products';
import { DEFAULT_CURRENCY } from '../../utils/currency';

const ProductPrice = ({ product }) => formattedPrice(product.price[DEFAULT_CURRENCY].amount);

ProductPrice.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductPrice;
