import React from 'react';
import PropTypes from 'prop-types';
import EmailCardOnlyIcon from '../Icons/EmailCardOnly';

const ProductBadge = ({ product }) => {
  let Badge = null;

  // Check if "E-Card Only" badge should be shown.
  if (product.ecardActive && !product.postalCardActive) {
    Badge = <EmailCardOnlyIcon />;
  }

  return Badge ? <div className="product-badge">{Badge}</div> : null;
};

ProductBadge.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductBadge;
